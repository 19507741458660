import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { App, ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Workbox } from 'workbox-window';
import APP from '../package.json';
import { AppContextProvider } from './AppContext';
import Routes from './Routes';
import SocketContextProvider from './SocketContext';
import client from './apollo';
import AppComponentContainer from './components/AppComponentContainer';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './styles/main.less';
import Styles from './styles/style.json';

// eslint-disable-next-line no-undef
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/sw.js');
  wb.register();
}

Sentry.init({
  dsn: process.env?.REACT_APP_SENTRY_DSN,
  environment: process.env?.REACT_APP_ENV,
  tracesSampleRate: 1.0
});

// eslint-disable-next-line no-console
console?.log('APP_VERSION ', APP.version);

// eslint-disable-next-line no-undef
const root = ReactDOM?.createRoot(document?.getElementById('root'));
root?.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <SocketContextProvider>
        <App>
          {/* Adding antd apps container to render components */}
          <AppComponentContainer />
          <ConfigProvider theme={Styles}>
            <Routes />
          </ConfigProvider>
        </App>
      </SocketContextProvider>
    </AppContextProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
