import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LIST_TYPES,
  MODULES,
  ROUTES,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { modalContext } from '../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { DELETE_ANALYTIC_LINK } from './graphql/Mutations';
import { GET_ANALYTICS_LINKS } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.analyticLinks?.analyticLinks ?? [],
  count: data?.analyticLinks?.count ?? 0
});

const variablesSelector = ({ offset, limit, search }) => ({
  filters: {
    skip: offset,
    limit,
    search
  },
  options: {
    pagination: true
  }
});

const Action = ({ id, data, isDeleteAllowed, removeItem, linksRef }) => {
  const history = useHistory();
  const [deleteAnalyticLink] = useMutation(DELETE_ANALYTIC_LINK, {
    onError() {}
  });
  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ANALYTICS,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const handleEdit = () => {
    history?.push(`${ROUTES?.ANALYTICS_MODULE}/${id}/edit`);
  };

  const handleDelete = () => {
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this Analytic Link?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk() {
        deleteAnalyticLink({
          variables: { where: { id } }
        })
          .then(() => {
            if (removeItem) {
              linksRef?.current?.refetchLinks();
              removeItem(id);
            }
          })
          .catch();
      }
    });
  };

  return (
    <>
      <Tooltip title={`${isViewOnly ? 'View' : 'Edit'}`}>
        <Button
          type="text"
          className="text-btn"
          onClick={handleEdit}
          disabled={data?.isDefault}
        >
          {isViewOnly ? <EyeFilled /> : <EditOutlined />}
        </Button>
      </Tooltip>
      {isDeleteAllowed && (
        <Tooltip title="Delete">
          <Button type="text" className="text-btn" onClick={handleDelete}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

const AnalyticsModule = ({ history, linksRef }) => {
  const filterProps = useFilterBar();

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ANALYTICS,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.ANALYTICS,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);

  const handleShowModal = () => {
    history?.push(`${ROUTES?.ANALYTICS_MODULE}/add`);
  };

  const columns = useMemo(
    () => ({ removeItem }) => [
      {
        title: 'Key',
        dataIndex: 'key',
        ellipsis: true,
        width: '15%'
      },
      {
        title: 'Label',
        dataIndex: 'label',
        ellipsis: true,
        width: '15%'
      },
      {
        title: 'iFrame embed',
        dataIndex: 'value',
        width: '50%'
      },
      {
        title: 'Action',
        dataIndex: 'id',
        render: (value, record) => (
          <Action
            id={value}
            data={record}
            removeItem={removeItem}
            isDeleteAllowed={isDeleteAllowed}
            isAddEditAllowed={isAddEditAllowed}
            linksRef={linksRef}
          />
        )
      }
    ],
    [isAddEditAllowed]
  );

  return (
    <div>
      <PageHeader
        menu={MODULES?.WORKSPACE_SETTINGS}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false,
          sort: false
        }}
        SearchProps={{ placeholder: 'Search Data Embed' }}
      />
      <PageList
        filters={filterProps?.filters}
        listMode={LIST_TYPES?.TABLE}
        query={GET_ANALYTICS_LINKS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({ ...props })?.filter((item) => item !== false)
        }}
        limit={20}
        queryOptions={{
          fetchPolicy: 'no-cache'
        }}
      />
    </div>
  );
};

export default AnalyticsModule;
